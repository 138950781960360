import { FC, useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../../../context/GlobalContext';
import React from 'react';
import OrderSummary from '../StepTwo/OrderSummary';
import { Button } from './../../../components/Core';
import FullScreenLoader from './../../../components/FullScreenLoader';
import { updateQueryParam } from './../../../utils/helperFn';
import API from '../../../api/api';

interface Props {}

export const RecontractStepThree: FC<Props> = (props: Props) => {
  const gContext: any = useContext(GlobalContext);
  const [oldOrder, setOldOrder] = useState(null) as any;

  const registrationData = gContext.goGetRegistrationData();

  useEffect(() => {
    updateQueryParam('step', '3');
    fetchOrder();
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchOrder = async () => {
    try {
      const query = `OrderModule/v1.0/db/Order/${
        gContext.goGetRegistrationData()?.previousOrderId
      }?entities=["OrderItem", "Address"]`;
      const response = await API.get(query);
      setOldOrder(response.data.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleConfirm = async () => {
    try {
      const query = `OrderModule/v1.0/orders/recontracting/send-quote`;
      await API.post(query, {
        contactId: gContext.goGetRegistrationData()?.contactId,
        addressId: gContext.goGetRegistrationData()?.addressId,
        offerId: gContext.goGetRegistrationData()?.offer?.id,
        products: gContext.goGetRegistrationData()?.orderItems.map((item: any) => ({
          id: item.id,
          properties: {
            Quantity: 1,
          },
        })),
      }).then(() => {
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          checkoutStep: 4,
        });

        const updatedStepsData = { ...gContext.goGetRecontractSteps() };

        if (updatedStepsData.hasOwnProperty(4)) {
          updatedStepsData[4] = {
            ...updatedStepsData[4],
            stepVisited: true,
          };
          gContext.goSetRecontractSteps(updatedStepsData);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <FullScreenLoader title="Processing request ..." />
      <Container>
        <Row className="justify-content-center mt-3">
          <Col sm={12} lg={4} className="old-offer-wrapper">
            <Row className="old-offer-container">
              <Col xs={12} className="pt-2 pb-3">
                <span
                  style={{
                    padding: '5px 10px',
                    borderRadius: '50px',
                    fontSize: '1rem',
                    background: '#07131e',
                    color: 'white',
                    fontWeight: 700,
                  }}
                >
                  Old plan
                </span>
              </Col>

              {/* Your Address */}
              <Col xs={12} className="pt-2">
                <span style={{ fontWeight: 800 }}>Your address</span>
              </Col>
              <Col xs={12} className="pb-2">
                <span style={{ fontWeight: 100 }}>{oldOrder?.title || '-'}</span>
              </Col>

              {/* Your Plan */}
              <Col xs={12} className="pt-2">
                <span style={{ fontWeight: 800 }}>Your plan</span>
              </Col>
              <Col xs={12} className="pb-2">
                <span style={{ fontWeight: 100 }}>
                  {
                    oldOrder?.OrderItem?.dbRecords.filter(
                      (item) => item.properties.ProductType === 'BASE_PRODUCT',
                    )[0].title
                  }
                  : £
                  {parseFloat(
                    oldOrder?.OrderItem?.dbRecords.filter(
                      (item) => item.properties.ProductType === 'BASE_PRODUCT',
                    )[0].properties.TotalPrice,
                  ).toFixed(2)}
                </span>
              </Col>

              <Col xs={12} className="pt-2">
                <span style={{ fontWeight: 800 }}>Your add ons</span>
              </Col>

              {oldOrder?.OrderItem?.dbRecords.filter(
                (item) => item.properties.ProductType === 'ADD_ON_PRODUCT',
              ).length === 0 ? (
                <Col xs={12} className="pb-2">
                  -
                </Col>
              ) : (
                oldOrder?.OrderItem?.dbRecords
                  .filter((item) => item.properties.ProductType === 'ADD_ON_PRODUCT')
                  .map((addon: any) => {
                    return (
                      <Col xs={12} className="pb-2">
                        <span style={{ fontWeight: 100 }}>
                          {addon.title || 'Unknown addon'}: £{addon.properties?.TotalPrice}
                        </span>
                      </Col>
                    );
                  })
              )}

              <Col xs={12} className={'pt-2'}>
                <span style={{ fontWeight: 800 }}>Your contract</span>
              </Col>
              <Col xs={12} className="pb-2">
                <span style={{ fontWeight: 100 }}>
                  {oldOrder?.properties?.ContractType === 'MONTHLY'
                    ? 'No Contract'
                    : oldOrder?.properties?.ContractType.split('_')[1] + ' months'}
                </span>
              </Col>

              <Col xs={12} className="pt-2">
                <span style={{ fontWeight: 800 }}>Your monthly price</span>
              </Col>
              <Col xs={12}>
                <span style={{ fontWeight: 100 }}>£{oldOrder?.properties?.TotalPrice}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={4} className="new-offer-wrapper">
            <OrderSummary registrationData={registrationData} type="DESKTOP" titleText="New plan" />
          </Col>

          <Col sm={12} className="text-center mt-0">
            <Button onClick={() => handleConfirm()} className="medium-button mt-5 blue-bg">
              Confirm
            </Button>
          </Col>

          {/* Go Back */}
          <Col sm={12} className="text-center mt-5">
            <p
              style={{ cursor: 'pointer' }}
              onClick={() =>
                gContext.goSetRegistrationData({
                  ...gContext.goGetRegistrationData(),
                  checkoutStep: 2,
                })
              }
            >
              ← Go Back
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
