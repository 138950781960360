import { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import RecontractDynamicPricingSection from '../../PricingSection/RecontractDynamicPricingSection';
import { updateQueryParam } from './../../../utils/helperFn';
import GlobalContext from '../../../context/GlobalContext';
import { Button } from "../../../components/Core";

interface Props { 
  location: string,
  fetchedOffer: any
}

export const RecontractStepOne = (props: Props) => {
  const gContext: any = useContext(GlobalContext);
  useEffect(() => {
    updateQueryParam('step', '1')
  }, []);

  return (
    <>
      <Row className="justify-content-center">
        <Col sm={12} md={12} className="text-center mb-3 mt-4">
          <RecontractDynamicPricingSection location={props.location} fetchedOffer={props.fetchedOffer}/>
          <Button
              onClick={() =>
                gContext.goSetRegistrationData({
                  ...gContext.goGetRegistrationData(),
                  checkoutStep: 2,
                })
              }
              className="medium-button mt-5 blue-bg"
          >Next →
          </Button>
        </Col>
      </Row>
    </>
  );
};
