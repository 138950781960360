import React, { useContext, useEffect } from 'react';
import { FC } from 'react';
import GlobalContext from '../../../../context/GlobalContext';
import { TDbRecordEntityTransform } from '../../../../context/types';
import TSAPI from '../../../../api';

export const AddonsCalculationHooks: FC = () => {
  const gContext: any = useContext(GlobalContext);

  const offer = gContext.goGetRegistrationData()?.offer;

  // On component mount fetch addon prices.
  useEffect(() => {
    if (offer) {
      getAddonPrices();
    }
  }, []);

  const getAddonPrices = () => {
    const Products = gContext?.goGetRegistrationData()?.offer?.Product?.dbRecords;

    if (Products?.length! > 0) {
      const quoteProducts = Products?.filter(
        (product: any) => product.properties.Type === 'ADD_ON_PRODUCT',
      ).map((product: TDbRecordEntityTransform) => ({
        recordId: product.id,
        relatedAssociationId:
          product.dbRecordAssociation && product.dbRecordAssociation.relatedAssociationId
            ? product.dbRecordAssociation.relatedAssociationId
            : null,
        properties: {
          Quantity: 1,
        },
      }));
      gContext.setQuoteLoading(true)
      TSAPI.post(`/OrderModule/v1.0/orders/quote`, {
        discountId: null, // <-- not final, there will be discounts
        products: quoteProducts,
      })
        .then((response: any) => {
          let addons = response.data?.data?.OrderItems;
          if (addons.length! > 0) {
            addons = addons.map((addon: any) => ({
              title: addon.title,
              price: addon.properties.UnitPrice,
            }));
            gContext.goSetRegistrationData({
              ...gContext.goGetRegistrationData(),
              addonPrices: addons,
            });
          }
          gContext.setQuoteLoading(false)
        })
        .catch((error: any) => {
          gContext.setQuoteLoading(false)
        });
    }
  };

  return <></>;
};
