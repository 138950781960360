import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import GlobalContext from '../../../context/GlobalContext';
import React from 'react';
import OrderSummary from './OrderSummary';
import BigRoundButton from '../../../components/BigRoundButton';
import AddonSection from './AddonSection';
import './styles.scss';
import { isMobile } from 'react-device-detect';
import { updateQueryParam } from './../../../utils/helperFn';
import { toast } from 'react-toastify';

interface Props { }

export const RecontractStepTwo: FC<Props> = (props: Props) => {
  const gContext: any = useContext(GlobalContext);

  const registrationData = gContext.goGetRegistrationData();
  const addonsSelected = gContext.goGetAddonsSelected()
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    updateQueryParam('step', '2')
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    const selectedAddons = gContext.goGetRegistrationData().orderItems?.filter(item => item.properties.Type === 'ADD_ON_PRODUCT');

    selectedAddons?.forEach(addon => {
      if (addon.properties.DisplayName.indexOf('YouPhone') > -1) {
        gContext.goSetAddonsSelected({
          ...gContext.goGetAddonsSelected(),
          YouPhone: true,
        });  
      } else {
        gContext.goSetAddonsSelected({
          ...gContext.goGetAddonsSelected(),
          [addon.properties.DisplayName]: true,
        });  
      }
    });

    gContext.goGetRegistrationData().orderItems?.forEach(orderItem => {
      if (orderItem) {
        if (orderItem.properties.Type === 'ADD_ON_PRODUCT') {
          gContext.goSetToggleState({
            ...gContext.goGetToggleState(),
            [orderItem.properties.DisplayName]: true
          });
        }
      }
    });
  }, []);

  // Generic Addon add / remove
  const addProductToOffer = (title: string) => {
    const products = registrationData?.offer?.Product?.dbRecords;
    if (products) {
      const product = products.find((product: any) => product.properties.DisplayName === title);
      if (product) {
        gContext.goSetRegistrationData({
          ...registrationData,
          orderItems: [...registrationData?.orderItems, product],
        });
      }
    }
  };

  const removeProductFromOffer = (title: string) => {
    const newOrderItems = gContext.goGetRegistrationData()?.orderItems.filter(
      (item: any) => item.properties.DisplayName !== title,
    );
    gContext.goSetRegistrationData({
      ...registrationData,
      orderItems: newOrderItems,
    });
  };

  // When we remove YouPhone, we want to remove the YouPhone Plus as well
  const removeVoiceProductFromOffer = () => {
    const newOrderItems = gContext.goGetRegistrationData()?.orderItems.filter(
      (item: any) => item.properties.Category !== 'VOICE' && item.properties.DisplayName !== "Battery Pack",
    );
    gContext.goSetRegistrationData({
      ...registrationData,
      orderItems: newOrderItems,
    });
  };

  const addProduct = (product: any) => {
    addProductToOffer(product.properties.DisplayName);
    gContext.goSetAddonsSelected({
      ...gContext.goGetAddonsSelected(),
      [product.properties.DisplayName]: true,
    });
  }

  const removeProduct = (product: any) => {

    if (
        product.properties.DisplayName.includes('YouMesh') && (
        gContext.goGetRegistrationData()?.recontractResponse.nextProducts.filter(item => item.properties.DisplayName === 'YouMesh').length > 0 ||
        gContext.goGetRegistrationData()?.recontractResponse.nextProducts.filter(item => item.properties.DisplayName === 'YouMesh Pro').length > 0
      )) {
      toast.error('You will need to mail back the mesh devices to us within 14 days. If you do not return the devices, you will be charged for them.');
    }

    if (
      product.properties.DisplayName === 'YouPhone' &&
      (
        gContext.goGetRegistrationData()?.recontractResponse.nextProducts.filter(item => item.properties.DisplayName === 'YouPhone').length > 0 ||
        gContext.goGetRegistrationData()?.recontractResponse.nextProducts.filter(item => item.properties.DisplayName === 'YouPhone Plus').length > 0 ||
        gContext.goGetRegistrationData()?.recontractResponse.nextProducts.filter(item => item.properties.DisplayName === 'YouPhone Pro').length > 0
      )) {
      toast.error('Removing this product will remove all voice services and you will no longer be able to make or receive calls.');
    }

    if (product.properties.DisplayName === 'YouPhone') {
      removeVoiceProductFromOffer();
      gContext.goSetAddonsSelected({
        ...gContext.goGetAddonsSelected(),
        YouPhone: false,
      });
      gContext.goSetToggleState({
        ...gContext.goGetToggleState(),
        'YouPhone Plus': false,
        'YouPhone Pro': false,
        'Battery Pack': false
      });
    } else {
      removeProductFromOffer(product.properties.DisplayName);
      gContext.goSetAddonsSelected({
        ...gContext.goGetAddonsSelected(),
        [product.properties.DisplayName]: false,
      });
    }
  };

  // Place your order validation logic here
  const canContinue = () => {
    if (
      registrationData?.orderItems?.length > 0 &&
      ((
        gContext.goGetToggleState()['Transfer landline number'] === true &&
        gContext.goGetRegistrationData().phoneNumber1 &&
        gContext.goGetRegistrationData().phoneNumber2 &&
        gContext.goGetRegistrationData().phoneNumber3 &&
        gContext.goGetRegistrationData().portFromProvider) ||
        gContext.goGetToggleState()['Transfer landline number'] === false
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onContinue = () => {
    setFormSubmitted(true)
    if (canContinue()) {
      gContext.goSetRegistrationData({
        ...gContext.goGetRegistrationData(),
        checkoutStep: 3,
      });

      const updatedStepsData = { ...gContext.goGetSteps() };
      if (updatedStepsData.hasOwnProperty('3')) {
        updatedStepsData['3'] = {
          ...updatedStepsData['3'],
          stepVisited: true,
        };
        gContext.goSetSteps(updatedStepsData);
      }
    }
  };

  let mainAddons = registrationData?.offer?.Product?.dbRecords.filter(
    (item: any) => (
      item.properties.Type === 'ADD_ON_PRODUCT' &&
      (
        item.type !== "LANDLINE" ||
        item.title === 'YouPhone'
      )
    )
  )

  let productToRemove = ''

  if (
    registrationData?.selectedProduct?.properties?.DisplayName?.indexOf('You 50') > -1 ||
    registrationData?.selectedProduct?.properties?.DisplayName?.indexOf('You 150') > -1 ||
    registrationData?.selectedProduct?.properties?.DisplayName?.indexOf('You 500') > -1 ||
    registrationData?.selectedProduct?.properties?.DisplayName?.indexOf('You 1000') > -1
  ) {
    productToRemove = 'YouMesh Pro';
  } else {
    productToRemove = 'YouMesh';
  }

  mainAddons = mainAddons?.filter(item => item.properties.DisplayName !== productToRemove)

  const sortedAddons = mainAddons?.sort(function (a, b) {
    return parseInt(a.properties.ManufacturerSku) - parseInt(b.properties.ManufacturerSku)
  });
  return (
    <div className="step-three-container">
      <Row className="justify-content-center">
        <Col sm={12} lg={8} className="mb-3 mt-3 p-0">
          <Row className="justify-content-between">
            {/* Left Panel */}
            <Col xs={12} md={7} className={`no-mobile-top-space p-0`}>
              <div>
                <Row className="p-2">
                  {
                    sortedAddons?.map((element: any) => {
                      return <AddonSection
                        title={element.properties.DisplayName}
                        description={element.properties.DescriptionLine1}
                        added={addonsSelected[element.properties.DisplayName]}
                        onAdd={() => addProduct(element)}
                        onRemove={() => removeProduct(element)}
                        formSubmitted={formSubmitted}
                      />
                    })
                  }

                  {/* Continue Button */}
                  <Col xs={12} className="mt-4">
                    <Row className="justify-content-center">
                      <Col xs={12} md={6}>
                        <BigRoundButton
                          title="Continue"
                          color="alternative"
                          className={!canContinue() ? 'invalid' : ''}
                          onClick={onContinue}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Right Panel */}
            {!isMobile ? (
              <Col
                key={1}
                xs={12}
                md={4}
                className={`p-3 mt-1`}
                style={{ background: '#E6FCF5', borderRadius: 12 }}
              >
                <OrderSummary registrationData={registrationData} type="DESKTOP" titleText='Key info'/>
              </Col>
            ) : (
              <OrderSummary
                registrationData={registrationData}
                type={isMobile ? 'MOBILE' : 'DESKTOP'}
                titleText='Key info'
              />
            )}
          </Row>
        </Col>

        <Col sm={12} className="text-center mt-5">
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                checkoutStep: 1,
              })
            }
          >
            ← Go Back
          </p>
        </Col>
      </Row>
    </div>
  );
};
