import React, { useContext } from 'react';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import BigRoundButton from '../../../../components/BigRoundButton';
import wifiIcon from '../../../../assets/image/svg/wifi.svg';
import wifiPhoneIcon from '../../../../assets/image/svg/wifiphone.svg';
import globeIcon from '../../../../assets/image/svg/globe.svg';
import GlobalContext from '../../../../context/GlobalContext';
import AnimateHeight from 'react-animate-height';
import YouPhoneExtension from './YouPhoneExtension';
import { AddonsCalculationHooks } from '../AddonsCalculationHooks';

interface Props {
  onAdd: Function;
  onRemove: Function;
  title: string;
  formSubmitted: boolean;
  description: string;
  added: any;
}

const AddonSection: FC<Props> = (props: Props) => {
  const { onAdd, onRemove, title, added, description } = props;
  const gContext: any = useContext(GlobalContext);
  const registrationData = gContext.goGetRegistrationData();

  const addonPrice = () => {
    if (registrationData) {
      // console.log('debug: AddonSection.tsx', registrationData)
      const addons = registrationData?.addonPrices;
      const addon = addons?.find((addon: any) => addon?.title === title);
      return addon?.price;
    }
  };

  const renderIconForAddon = (): any => {
    const { title } = props;
    if (title.toLowerCase().indexOf('mesh') > -1) {
      return (
        <img
          src={wifiIcon}
          alt=""
          className="img-fluid mr-2"
          style={{ maxWidth: 12, color: 'black' }}
        />
      );
    } else if (title.toLowerCase().indexOf('phone') > -1) {
      return (
        <img
          src={wifiPhoneIcon}
          alt=""
          className="img-fluid mr-2"
          style={{ maxWidth: 16, marginBottom: 4, color: 'black' }}
        />
      );
    } else if (title.toLowerCase().indexOf('static') > -1) {
      return (
        <img
          src={globeIcon}
          alt=""
          className="img-fluid mr-2"
          style={{ maxWidth: 18, marginBottom: 4, color: 'black' }}
        />
      );
    } else {
      return <span>No Icon</span>;
    }
  };

  return (
    <Col xs={12} className={`p-3 mt-0 mb-3 p-0${(title === 'YouPhone' && added) ? ' z-index-large' : ''}`} style={{ border: '1px solid black', borderRadius: 13 }}>
      <AddonsCalculationHooks />
      <Row className="align-items-center">
        <Col xs={12} md={5} lg={6} className="pb-3 pb-md-0 order-0">
          <span style={{ fontWeight: 800, fontSize: '1.1em' }} className="mt-2 addon-price-wrapper">
            {renderIconForAddon()}
            {title}
            {addonPrice() && (
              <>
                {(gContext.quoteLoading || gContext.totalLoading) && <div style={{ fontWeight: 400, fontSize: 14, display: 'inline-block' }} className="ml-2">Price loading...</div>}
                {
                  !gContext.quoteLoading && !gContext.totalLoading &&
                  <>
                    <div style={{ fontWeight: 400, fontSize: 14, display: 'inline-block' }} className="ml-2">
                      +£{addonPrice()}
                    </div>
                    <div style={{ fontWeight: 400, fontSize: 14, display: 'inline-block' }} className="ml-1">
                      per month
                    </div>
                  </>
                }
              </>
            )}
          </span>
        </Col>

        <Col xs={12} md={3} lg={3} className="order-2 order-md-1">
          <BigRoundButton
            title="No thanks"
            className='invert-colors'
            size="small"
            ghost={added === undefined || added === true}
            color={added === false ? 'blue' : undefined}
            onClick={onRemove}
            disabled={gContext.quoteLoading || gContext.totalLoading}
          />
        </Col>
        <Col xs={12} md={3} lg={3} className="order-1 order-md-2 pb-3 pb-md-0">
          <BigRoundButton
            title={added ? 'Added' : 'Add'}
            className='invert-colors'
            size="small"
            ghost={added === undefined || added === false}
            color={added === true ? 'alternative' : undefined}
            onClick={!added && onAdd}
            disabled={gContext.quoteLoading || gContext.totalLoading}
          />
        </Col>

        {/* Description */}
        <Col xs={12} className="mt-4 order-3">
          <span style={{ fontWeight: 400 }}>{description}</span>
        </Col>
      </Row>
      
      <AnimateHeight
        id="example-panel"
        duration={500}
        height={title === 'YouPhone' && added ? 'auto' : 0} // see props documentation below
      >
        <Row className="item">
          <Col xs={12}>
            <YouPhoneExtension formSubmitted={props.formSubmitted} />
          </Col>
        </Row>
      </AnimateHeight>
      
    </Col>
  );
};

export default AddonSection;
