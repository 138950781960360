import React, { useContext, useEffect } from 'react';
import { FC } from 'react';
import GlobalContext from '../../../../context/GlobalContext';
import { TDbRecordEntityTransform } from '../../../../context/types';
import TSAPI from '../../../../api';

// This component will watch for changes in the global context and update the order calculation.
export const OrderCalculationHooks: FC = () => {
  const gContext: any = useContext(GlobalContext);

 
  return <></>;
};
