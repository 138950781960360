import React, { useContext, useEffect, useState } from 'react';
import { FC } from 'react';
import { TDbRecordEntityTransform, TRegistrationData } from '../../../../context/types';
import { Col, Row } from 'react-bootstrap';
import { Card } from 'antd';
import GlobalContext from '../../../../context/GlobalContext';
import moment from 'moment';
import { OrderCalculationHooks } from '../OrderItemsCalculationHooks';
import TSAPI from '../../../../api';
import './styles.scss';
import { PiCaretCircleUpBold, PiCaretCircleDownBold } from 'react-icons/pi';
interface Props {
  type: 'DESKTOP' | 'MOBILE';
  registrationData: TRegistrationData;
  titleText: string;
}

const OrderSummary: FC<Props> = (props: Props) => {
  const { registrationData, type, titleText } = props;
  const gContext: any = useContext(GlobalContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [orderSummary, setOrderSummary] = useState<
    | {
        TotalPrice: string;
        Subtotal: string;
        TotalDiscounts: string;
        TotalTaxAmount: string;
        OrderItems: any;
      }
    | undefined
  >(undefined);

  // For each order item update, save the summary to the global context.
  useEffect(() => {
    const orderItems = gContext.goGetRegistrationData()?.orderItems || [];
    if (orderItems.length > 0) {
      getQuote();
    }
  }, [JSON.stringify(gContext.goGetRegistrationData().orderItems)]);

  const getQuote = () => {
    const quoteProducts = gContext?.goGetRegistrationData()?.orderItems?.map(
      (product: TDbRecordEntityTransform) =>
        product && {
          id: product.id,
          properties: {
            Quantity: 1,
          },
        },
    );
    gContext.setTotalLoading(true);
    TSAPI.post(`/OrderModule/v1.0/orders/recontracting/get-order-summary`, {
      contactId: gContext?.goGetRegistrationData()?.contactId,
      addressId: gContext?.goGetRegistrationData()?.addressId,
      offerId: gContext?.goGetRegistrationData()?.offer?.id,
      products: quoteProducts,
    })
      .then((response: any) => {
        const summary = Object.assign(response.data?.data);
        if (summary && orderSummary?.TotalPrice !== summary.TotalPrice) {
          setOrderSummary({
            TotalPrice: summary.TotalPrice || undefined,
            Subtotal: summary.Subtotal || undefined,
            TotalDiscounts: summary.TotalDiscounts || undefined,
            TotalTaxAmount: summary.TotalTaxAmount || undefined,
            OrderItems: summary.OrderItems || undefined,
          });
          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            totalPrice: summary.TotalPrice || undefined,
          });
        }
        gContext.setTotalLoading(false);
      })
      .catch((error: any) => {
        gContext.setTotalLoading(false);
      });
  };

  const getDefaultPlanName = () => {
    let planName: string = '-';
    const orderItems: TDbRecordEntityTransform[] = gContext?.goGetRegistrationData()?.orderItems;

    if (orderItems?.length > 0) {
      const plan = orderItems.find(
        (item: TDbRecordEntityTransform) => item?.properties?.Type === 'BASE_PRODUCT',
      );
      if (plan) {
        planName = plan?.properties?.DisplayName;
      }
    } else {
      return '-';
    }

    return planName;
  };

  const getDefaultPlanPrice = () => {
    let planPrice: number = 0;
    const orderItems: TDbRecordEntityTransform[] = orderSummary?.OrderItems;

    if (orderItems?.length > 0) {
      const plan = orderItems.find(
        (item: TDbRecordEntityTransform) => item?.properties?.ProductType === 'BASE_PRODUCT',
      );
      if (plan) {
        planPrice = parseFloat(plan?.properties?.TotalPrice);
      }
    } else {
      return '-';
    }

    return planPrice.toFixed(2);
  };

  const activeAddons = () => {
    const orderItems: TDbRecordEntityTransform[] = orderSummary?.OrderItems;
    let addons: TDbRecordEntityTransform[] = [];

    if (orderItems?.length > 0) {
      addons = orderItems.filter(
        (item: TDbRecordEntityTransform) => item?.properties?.ProductType === 'ADD_ON_PRODUCT',
      );
    }
    return addons;
  };

  const getContractType = () => {
    let contractType: string = '-';
    const orderItems: TDbRecordEntityTransform[] = gContext?.goGetRegistrationData()?.orderItems;

    if (orderItems?.length > 0) {
      const baseProduct = orderItems.find(
        (item: TDbRecordEntityTransform) => item?.properties?.Type === 'BASE_PRODUCT',
      );
      if (baseProduct) {
        baseProduct?.properties?.ContractType === 'MONTHLY'
          ? (contractType = 'No Contract')
          : (contractType =
              gContext?.goGetRegistrationData()?.selectedProduct?.properties?.ContractType ===
              'MONTHLY'
                ? 'No Contract'
                : gContext
                    ?.goGetRegistrationData()
                    ?.selectedProduct?.properties?.ContractType.split('_')[1] + ' months');
      }
    }

    return contractType;
  };

  const renderSummary = () => {
    return (
      <Row className="summary-container">
        <Col xs={12} className="pt-2 pb-3">
          <span
            style={{
              padding: '5px 10px',
              borderRadius: '50px',
              fontSize: '1rem',
              background: '#07131e',
              color: 'white',
              fontWeight: 700,
            }}
          >
            {titleText}
          </span>
        </Col>

        {/* Your Address */}
        <Col xs={12} className="pt-2">
          <span style={{ fontWeight: 800 }}>Your address</span>
        </Col>
        <Col xs={12} className="pb-2">
          <span style={{ fontWeight: 100 }}>
            {registrationData?.searchPremiseResults?.title || '-'}
          </span>
        </Col>

        {/* Your Plan */}
        <Col xs={12} className="pt-2">
          <span style={{ fontWeight: 800 }}>Your plan</span>
        </Col>
        <Col xs={12} className="pb-2">
          <span style={{ fontWeight: 100 }}>
            {getDefaultPlanName()}: £{getDefaultPlanPrice()}
          </span>
          <span
            style={{ color: '#205CD4', cursor: 'pointer' }}
            className="ml-2"
            onClick={() =>
              gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                checkoutStep: 1,
                orderItems: [],
              })
            }
          >
            Change
          </span>
        </Col>

        {/* Active addons? */}
        {activeAddons().length > 0 && (
          <>
            <Col xs={12} className="pt-2">
              <span style={{ fontWeight: 800 }}>Your add ons</span>
            </Col>
            {activeAddons().map((addon: any) => {
              return (
                <Col xs={12} className="pb-2">
                  <span style={{ fontWeight: 100 }}>
                    {addon.title || 'Unknown addon'}: £{addon.properties?.TotalPrice}
                  </span>
                </Col>
              );
            })}
          </>
        )}

        {/* Your Contract */}
        <Col xs={12} className={'pt-2'}>
          <span style={{ fontWeight: 800 }}>Your contract</span>
        </Col>
        <Col xs={12} className="pb-2">
          <span style={{ fontWeight: 100 }}>{getContractType()}</span>
          <span
            style={{ color: '#205CD4', cursor: 'pointer' }}
            className="ml-2"
            onClick={() =>
              gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                checkoutStep: 1,
              })
            }
          >
            Change
          </span>
        </Col>

        {/* Your Monthly Price */}
        <Col xs={12} className="pt-2">
          <span style={{ fontWeight: 800 }}>Your monthly price</span>
        </Col>
        <Col xs={12} className="pb-2">
          <span style={{ fontWeight: 100 }}>£{orderSummary?.TotalPrice}</span>
          {gContext.goGetRegistrationData()?.offer?.properties?.CustomerType === 'BUSINESS' && (
            <span>
              <b>&nbsp;(incl. VAT)</b>
            </span>
          )}
        </Col>
      </Row>
    );
  };

  const renderMobileSummary = () => {
    return (
      <Card
        size="small"
        className={`mobileSummaryRibbon ${isExpanded ? 'expanded' : 'collapsed'}`}
        title={
          <Col
            xs={12}
            className="pt-2 pb-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <PiCaretCircleDownBold
                className="pb-0"
                style={{ marginRight: 8, fontSize: '1.8em' }}
              />
            ) : (
              <PiCaretCircleUpBold
                className="pb-0"
                style={{ marginRight: 8, fontSize: '1.8em', paddingBottom: 8 }}
              />
            )}
            <span className="ribbonTitle" style={{ verticalAlign: 'middle' }}>
              Package summary{`: £${orderSummary?.TotalPrice}` || ''}
            </span>
          </Col>
        }
      >
        <Col xs={12} className="pt-2 pb-3">
          {isExpanded ? renderSummary() : <></>}
        </Col>
      </Card>
    );
  };

  return (
    <>
      <OrderCalculationHooks />
      {type === 'DESKTOP' ? renderSummary() : renderMobileSummary()}
    </>
  );
};

export default OrderSummary;
