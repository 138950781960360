import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import GlobalContext from '../../../context/GlobalContext';
import React from 'react';
import BigRoundButton from '../../../components/BigRoundButton';
import OrderSummary from '../StepTwo/OrderSummary';
import { Input, Box } from '../../../components/Core';
import API from '../../../api/api';
import FullScreenLoader from '../../../components/FullScreenLoader';
import { isMobile } from 'react-device-detect';
import { updateQueryParam } from '../../../utils/helperFn';
import { TinySwitch } from '../../../components/Core/Switch';
import { navigate } from "gatsby"

interface Props { }

export const RecontractStepFour: FC<Props> = (props: Props) => {
  const gContext: any = useContext(GlobalContext);

  const [termsAndConditionsConsent, setTermsAndConditionsConsent] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>('');
  const registrationData = gContext.goGetRegistrationData();
  const [marketingOptIn, setMarketingOptIn] = useState<boolean>(true);

  useEffect(() => {
    updateQueryParam('step', '4')
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const canContinue =
    termsAndConditionsConsent &&
    (!referralCode || referralCode.length === 8);

  const onContinue = () => {
    const previousOrderId = gContext.goGetRegistrationData().previousOrderId
    gContext.showFullScreenLoader();
    console.log('%cDebug: Order data', 'color: lime', gContext.goGetRegistrationData());
    API.post(`OrderModule/v1.0/orders/recontracting/apply`,  {
      orderId: previousOrderId,
      billingStartDate: gContext.goGetRegistrationData()?.recontractResponse?.billingStartDate,
      serviceStartDate: gContext.goGetRegistrationData()?.recontractResponse?.serviceStartDate,
      offerId: gContext.goGetRegistrationData()?.offer.id,
      products : gContext.goGetRegistrationData()?.orderItems.map((item: any) => ({
        id: item.id,
        properties: {
          Quantity: 1
        }
      })),
      skuDeviceMap: gContext.goGetRegistrationData()?.recontractResponse?.skuDeviceMap,
      skuPriceChangeMap: gContext.goGetRegistrationData()?.recontractResponse?.skuPriceChangeMap
    })
      .then((response) => {
        navigate('/success-recontract');
      })
      .catch((error) => {
        gContext.hideFullScreenLoader();
        gContext.setAPIErrorMsg({
          title: 'Recontract Error',
          message: error.response ? error.response?.data.message : error
        });
      });
  };

  return (
    <>
      <FullScreenLoader title="Processing request ..." />
      <Row className="justify-content-center">
        <Col sm={12} lg={11} className="mb-3 mt-3">
          <Row className="justify-content-around">
            {/* Left Panel */}
            <Col xs={12} md={6} className={`no-mobile-top-space`}>
              <Row className="p-2 no-mobile-top-space">
                <Col xs={12} className="mt-4 no-mobile-top-space">
                  <Row className="justify-content-left">
                    {/* -- Your Details -------------------------------------------- */}
                    <Col xs={12} className="mb-3">
                      <span style={{ fontWeight: 800 }}>Your Details</span>
                    </Col>
                    {/* FirstName */}
                    <Col xs={12} md={6} className="mb-4 mb-md-0">
                      <Input
                        disabled={true}
                        css={undefined}
                        placeholder="First name"
                        type=""
                        name="firstName"
                        value={gContext.goGetRegistrationData()?.personalData?.firstName || ''}
                      />
                    </Col>
                    {/* Lastname */}
                    <Col xs={12} md={6}>
                      <Input
                        disabled={true}
                        css={undefined}
                        placeholder="Last name"
                        type=""
                        name="lastName"
                        value={gContext.goGetRegistrationData()?.personalData?.lastName || ''}
                      />
                    </Col>
                    {/* Phone Number */}
                    <Col xs={12} className="mt-4">
                      <Input
                        disabled={true}
                        css={undefined}
                        placeholder="Phone number"
                        type=""
                        name="phoneNumber"
                        value={gContext.goGetRegistrationData()?.personalData?.phoneNumber || ''}
                      />
                    </Col>
                    {/* Email */}
                    <Col xs={12} className="mt-4">
                      <Input
                        disabled={true}
                        css={undefined}
                        placeholder="Phone number"
                        type=""
                        name="phoneNumber"
                        value={gContext.goGetRegistrationData()?.personalData?.email || ''}
                      />
                    </Col>
                    {
                      gContext.goGetRegistrationData()?.offer?.properties?.CustomerType === 'BUSINESS' &&
                      <>
                        <Col xs={12} className="mt-4">
                          <Input
                            disabled={true}
                            css={undefined}
                            placeholder="Company name"
                            type=""
                            name="companyName"
                            value={gContext.goGetRegistrationData()?.personalData?.companyName || ''}
                          />
                        </Col>
                        <Col xs={12} className="mt-4">
                          <Input
                            disabled={true}
                            css={undefined}
                            placeholder="Company position"
                            type=""
                            name="companyPosition"
                            value={gContext.goGetRegistrationData()?.personalData?.companyPosition || ''}
                          />
                        </Col>
                      </>
                    }

                    {/* Landline number - show only if LOA + phone number available */}
                    {gContext.goGetRegistrationData()?.LOAAuthorized &&
                      gContext.goGetRegistrationData()?.phoneNumber1 &&
                      gContext.goGetRegistrationData()?.phoneNumber2 &&
                      gContext.goGetRegistrationData()?.phoneNumber3 && (
                        <Col xs={12} className="mt-4">
                          <Input
                            disabled={true}
                            css={undefined}
                            placeholder="Landline number"
                            type=""
                            name="phoneNumber"
                            maxLength="8"
                            value={`${gContext.goGetRegistrationData()?.phoneNumber1} ${gContext.goGetRegistrationData()?.phoneNumber2} ${gContext.goGetRegistrationData()?.phoneNumber3}`}
                          />
                        </Col>
                      )}

                    {

                      /*
                  <Col xs={referralCode ? 10 : 12} sm={referralCode ? 11 : 12} className="mt-5">
                    <Box mb={3} className="position-relative">
                      <Input
                        type=""
                        name="referralCode"
                        placeholder="Referral Code"
                        className="uppercase-input"
                        maxLength="8"
                        value={referralCode.toUpperCase()}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const inputVal = e.target.value.replace(/[^A-Za-z]/g, '');
                          setReferralCode(inputVal);
                          gContext.goSetRegistrationData({
                            ...gContext.goGetRegistrationData(),
                            referralCode: inputVal,
                          });
                        }}
                        css={undefined}
                      />
                    </Box>
                  </Col>
                  {referralCode && (
                    <Col xs={1} sm={1} className="pt-3 text-right">
                      {referralCode.length === 8 && '✅'}
                      {referralCode.length < 8 && '❌'}
                    </Col>
                  )}
                  */
                    }

                    {/* Term Agreement */}
                    <Col xs={3} md={2} className="pt-2 pb-2 mt-2">
                      </Col>
                      <Col xs={9} md={10} className="pt-2 pb-2">
                      <span className='consent-info'>Please read the following before joining YouFibre:</span>
                      <br />
                      <span className='consent-info'>
                        <a
                          target="_blank"
                          href="/legal/website-terms-and-conditions"
                          style={{ color: '#123EE0', textDecoration: 'underline' }}
                        >
                          Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a
                          target="_blank"
                          href="/legal/privacy-and-cookie-policy"
                          style={{ color: '#123EE0', textDecoration: 'underline' }}
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </Col>

                    {/* Checkbox: Agree to TC and Privacy Policy */}
                    <Col sm={12} className="mt-2">
                      <Row>
                        <Col xs={3} md={2} className="pt-1">
                          <TinySwitch setValue={(e: any) => setTermsAndConditionsConsent(e)} />
                        </Col>
                        <Col xs={9} md={10}>
                          <span className="consent-info">
                            I agree to YouFibre Terms & Conditions and I've read YouFibre
                            Privacy Policy
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} className="mt-4">
                      {/* Continue Button */}
                      <Col xs={12} className="mt-5">
                        <Row className="justify-content-center">
                          <Col xs={12} md={6}>
                            <BigRoundButton
                              disabled={!canContinue}
                              title="Submit your order"
                              onClick={onContinue}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* Right Panel */}
            {!isMobile ? (
              <Col
                key={1}
                xs={12}
                md={4}
                className={`p-3 mt-4`}
                style={{ background: '#E6FCF5', borderRadius: 12 }}
              >
                <OrderSummary registrationData={registrationData} type="DESKTOP" titleText="Key info"/>
              </Col>
            ) : (
              <OrderSummary
                registrationData={registrationData}
                type={isMobile ? 'MOBILE' : 'DESKTOP'}
                titleText='Key info'
              />
            )}
          </Row>
        </Col>
        {/* Go Back */}
        <Col sm={12} className="text-center mt-5">
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                checkoutStep: 3,
              })
            }
          >
            ← Go Back
          </p>
        </Col>
      </Row>
    </>
  );
};
