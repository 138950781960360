import React, { useContext, useState, useEffect } from 'react';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TinySwitch } from '../../../../components/Core/Switch';
import Divider from '../../../../components/Core/Divider';
import { Link } from 'gatsby';
import GlobalContext from '../../../../context/GlobalContext';
import AnimateHeight from 'react-animate-height';
import { Input } from 'antd';
import './styles.scss';
import { TDbRecordEntityTransform } from '../../../../context/types';
import { isMobile } from 'react-device-detect';
import { Select } from "../../../../components/Core";
import API from './../../../../api/api';

const YouPhoneExtension = (props: any) => {
  const gContext: any = useContext(GlobalContext);

  const [portFromProviderOptions, setPortFromProviderOptions] = useState([])

  useEffect(() => {
    API.get(`/SchemaModule/v1.0/schemas/bymodule/?moduleName=ServiceModule&entityName=VoiceConfiguration`)
      .then((resp: any) => {
        const voiceConfigResp = resp.data.data
        if (voiceConfigResp) {
          const pFp = voiceConfigResp.columns.find(item => item.name === 'PortFromProvider')
          setPortFromProviderOptions(pFp.options.sort((a: any, b: any) => a.position - b.position))
        }
      })
      .catch((error: any) => {
        console.log('error: ', error)
      })
  }, [])


  const handleYouPhonePlusChange = (value: boolean) => {
    // When YouPhone Plus is added, we need to remove the regular YouPhone
    // package from the orderItems array and put YouPhone Plus instead.

    const offerProducts = gContext.goGetRegistrationData()?.offer?.Product?.dbRecords;
    let orderItems = gContext.goGetRegistrationData()?.orderItems;

    if (orderItems?.length! > 0 && offerProducts?.length! > 0) {
      const youPhonePlusProduct = offerProducts.find(
        (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone Plus',
      );
      const youPhoneProduct = offerProducts.find(
        (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone',
      );

      // Add YouPhone Plus, remove YouPhone  and YouPhone Pro
      if (value === true) {
        orderItems = orderItems?.filter(
          (item: any) =>
            item.properties.DisplayName !== 'YouPhone' &&
            item.properties.DisplayName !== 'YouPhone Pro',
        );
        orderItems?.push(youPhonePlusProduct);
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          orderItems,
        });
      } else {
        // Remove YouPhone Plus, add YouPhone
        orderItems = orderItems?.filter(
          (item: any) => item.properties.DisplayName !== 'YouPhone Plus',
        );
        orderItems?.push(youPhoneProduct);
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          orderItems,
        });
      }
      gContext.goSetToggleState({
        ...gContext.goGetToggleState(),
        'YouPhone Plus': value,
        'YouPhone Pro': false,
      });
    }
  };

  const handleYouPhoneProChange = (value: boolean) => {
    // When YouPhone Plus is added, we need to remove the regular YouPhone
    // package from the orderItems array and put YouPhone Plus instead.

    const offerProducts = gContext.goGetRegistrationData()?.offer?.Product?.dbRecords;
    let orderItems = gContext.goGetRegistrationData()?.orderItems;

    if (orderItems?.length! > 0 && offerProducts?.length! > 0) {
      const youPhoneProProduct = offerProducts.find(
        (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone Pro',
      );
      const youPhoneProduct = offerProducts.find(
        (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone',
      );

      // Add YouPhone Plus, remove YouPhone and YouPhone Plus
      if (value === true) {
        orderItems = orderItems?.filter(
          (item: any) =>
            item.properties.DisplayName !== 'YouPhone' &&
            item.properties.DisplayName !== 'YouPhone Plus',
        );
        handleYouPhonePlusChange(false);
        orderItems?.push(youPhoneProProduct);
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          orderItems,
        });
      } else {
        // Remove YouPhone Pro, add YouPhone
        orderItems = orderItems?.filter(
          (item: any) => item.properties.DisplayName !== 'YouPhone Pro',
        );
        orderItems?.push(youPhoneProduct);
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          orderItems,
        });
      }
      gContext.goSetToggleState({
        ...gContext.goGetToggleState(),
        'YouPhone Plus': false,
        'YouPhone Pro': value,
      });
    }
  };

  const handleAccessibilityChange = (value: boolean) => {
    const offerProducts = gContext.goGetRegistrationData()?.offer?.Product?.dbRecords;
    let orderItems = gContext.goGetRegistrationData()?.orderItems;

    if (orderItems?.length! > 0 && offerProducts?.length! > 0) {
      const batteryPackProduct = offerProducts.find(
        (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'Battery Pack',
      );

      // Add Battery Pack
      if (value === true) {
        orderItems?.push(batteryPackProduct);
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          orderItems,
        });
      } else {
        // Remove Battery Pack
        orderItems = orderItems?.filter(
          (item: any) => item.properties.DisplayName !== 'Battery Pack',
        );
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          orderItems,
        });
      }
    }
    gContext.goSetToggleState({
      ...gContext.goGetToggleState(),
      "Battery Pack": value,
    });
  };

  const handleTransferPhoneChange = (value: boolean) => {
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      phoneNumber1: '+44',
      phoneNumber2: '',
      phoneNumber3: '',
      LOAAuthorized: value,
    });
    gContext.goSetToggleState({
      ...gContext.goGetToggleState(),
      'Transfer landline number': value,
    });
  };

  const getYouPhonePlusPrice = (): string => {
    let response: string = '';

    if (gContext.goGetRegistrationData()?.addonPrices?.length! > 0) {
      const youPhone = gContext.goGetRegistrationData()?.addonPrices?.find(
        (addon: { title: string; price: string }) => addon.title === 'YouPhone',
      );
      const youPhonePlus = gContext.goGetRegistrationData()?.addonPrices?.find(
        (addon: { title: string; price: string }) => addon.title === 'YouPhone Plus',
      );

      if (youPhone && youPhonePlus) {
        const youPhonePrice = parseFloat(youPhone.price);
        const youPhonePlusPrice = parseFloat(youPhonePlus.price);
        return (youPhonePlusPrice - youPhonePrice).toFixed(2);
      }
    }

    return response;
  };

  const getYouPhoneProPrice = (): string => {
    let response: string = '';

    if (gContext.goGetRegistrationData()?.addonPrices?.length! > 0) {
      const youPhone = gContext.goGetRegistrationData()?.addonPrices?.find(
        (addon: { title: string; price: string }) => addon.title === 'YouPhone',
      );
      const youPhonePro = gContext.goGetRegistrationData()?.addonPrices?.find(
        (addon: { title: string; price: string }) => addon.title === 'YouPhone Pro',
      );

      if (youPhone && youPhonePro) {
        const youPhonePrice = parseFloat(youPhone.price);
        const youPhoneProPrice = parseFloat(youPhonePro.price);
        return (youPhoneProPrice - youPhonePrice).toFixed(2);
      }
    }

    return response;
  };

  const handlePhoneTransferField = (value: string) => {
    if (value.startsWith('0') && value.length > 0) {
      value = value.slice(1);
    }
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      phoneNumber2: value,
    })
  }

  return (
    <Row className="mt-1">
      {/* YouPhone Plus */}
      {
        gContext.goGetRegistrationData()?.offer?.Product?.dbRecords.find(
          (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone Plus') &&
        <>
          <Col xs={12} className="p-0">
            <Divider />
          </Col>
          <Col xs={12} className="pl-2 pr-2">
            <Row>
              <Col xs={9} sm={8} className="p-1" style={{ fontWeight: 800, fontSize: '1.3em' }}>
                <span className="pl-3 pb-2">YouPhone Plus!</span>
                <div
                  className="ml-2"
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    display: 'inline-block',
                    paddingLeft: isMobile ? 7 : 0,
                    fontFamily: 'DM Sans',
                    color: 'rgb(7, 19, 30)',
                  }}
                >
                  +£{getYouPhonePlusPrice()} more per month
                </div>
              </Col>
              <Col xs={3} sm={4} className="text-right mt-1 p-1">
                <TinySwitch
                  setValue={handleYouPhonePlusChange}
                  value={gContext.goGetToggleState()['YouPhone Plus']}
                />
              </Col>
              <Col xs={12} className="ml-2 mt-2">
                {
                  gContext.goGetRegistrationData()?.offer?.Product?.dbRecords.find(
                    (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone Plus',
                  ).properties.DescriptionLine1
                }
              </Col>
            </Row>
          </Col>
        </>
      }

      {/* YouPhone Pro */}
      {
        gContext.goGetRegistrationData()?.offer?.Product?.dbRecords.find(
          (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone Pro') &&
        <>
          <Col xs={12} className="p-0">
            <Divider />
          </Col>
          <Col xs={12} className="pl-2 pr-2">
            <Row>
              <Col xs={9} sm={8} className="p-1" style={{ fontWeight: 800, fontSize: '1.3em' }}>
                <span className="pl-3 pb-2">YouPhone Pro!</span>
                <div
                  className="ml-2"
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    display: 'inline-block',
                    fontFamily: 'DM Sans',
                    paddingLeft: isMobile ? 7 : 0,
                    color: 'rgb(7, 19, 30)',
                  }}
                >
                  +£{getYouPhoneProPrice()} more per month
                </div>
              </Col>
              <Col xs={3} sm={4} className="text-right mt-1 p-1">
                <TinySwitch
                  setValue={handleYouPhoneProChange}
                  value={gContext.goGetToggleState()['YouPhone Pro']}
                />
              </Col>
              <Col xs={12} className="ml-2 mt-2">
                {
                  gContext.goGetRegistrationData()?.offer?.Product?.dbRecords.find(
                    (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'YouPhone Pro',
                  ).properties.DescriptionLine1
                }
              </Col>
            </Row>
          </Col>
        </>
      }

      {/* Requirements */}
      {
        gContext.goGetRegistrationData()?.offer?.Product?.dbRecords.find(
          (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'Battery Pack') &&
        <>
          <Col xs={12} className="p-0">
            <Divider />
          </Col>
          <Col xs={12} className="pl-2 pr-2">
            <Row>
              <Col xs={9} sm={8} className="p-1" style={{ fontWeight: 800, fontSize: '1.3em' }}>
                <span className="pl-3 pb-2">Accessibility</span>
              </Col>
              <Col xs={3} sm={4} className="text-right mt-1 p-1">
                <TinySwitch
                  setValue={handleAccessibilityChange}
                  value={gContext.goGetToggleState()['Battery Pack']}
                />
              </Col>
              <Col xs={12} className="ml-2 mt-2">
                {
                  gContext.goGetRegistrationData()?.offer?.Product?.dbRecords.find(
                    (product: TDbRecordEntityTransform) => product.properties.DisplayName === 'Battery Pack',
                  ).properties.DescriptionLine1
                }
              </Col>
            </Row>
          </Col>
        </>
      }

      {/* Transfer Phone */}
      {
        !gContext.goGetRegistrationData()?.hasVoice &&
        !gContext.goGetRegistrationData()?.containsYouPhone &&
        <>
          <Col xs={12} className="p-0">
            <Divider />
          </Col>
          <Col xs={12} className="pl-2 pr-2">
            <Row>
              <Col xs={9} sm={8} className="p-1" style={{ fontWeight: 800, fontSize: '1.3em' }}>
                <span className="pl-3 pb-2">Transfer landline number</span>
              </Col>
              <Col xs={3} sm={4} className="text-right mt-1 p-1">
                <TinySwitch
                  setValue={handleTransferPhoneChange}
                  value={gContext.goGetToggleState()['Transfer landline number']}
                />
              </Col>
              <Col xs={12} className="ml-2 mt-2">
                By selecting this option, you are authorising LOA.
              </Col>

              <AnimateHeight
                id="example-panel"
                style={{ width: '97%' }}
                duration={500}
                height={gContext.goGetRegistrationData()?.LOAAuthorized ? 'auto' : 0} // see props documentation below
              >
                <Col xs={12} className="ml-2 mt-4">
                  {props.formSubmitted && !gContext.goGetRegistrationData().portFromProvider && gContext.goGetRegistrationData().LOAAuthorized && <p className="invalid-label">Required field</p>}
                  <Select
                    isClearable={true}
                    value={gContext.goGetRegistrationData().portFromProvider}
                    autocomplete="off"
                    options={portFromProviderOptions}
                    isSearchable={true}
                    placeholder="Select current landline provider"
                    className={"reactSelect transfer-landline-select"}
                    onChange={option => {
                      gContext.goSetRegistrationData({
                        ...gContext.goGetRegistrationData(),
                        portFromProvider: option ? option : undefined
                      })
                    }
                    }
                  />
                </Col>
                <Col xs={12} className="ml-2 mt-4">
                  <Row className="justify-content-between">
                    <Col xs={12} md={4} className="text-left mb-4 mb-md-0">
                      {props.formSubmitted && !gContext.goGetRegistrationData().phoneNumber1 && gContext.goGetRegistrationData().LOAAuthorized && <p className="invalid-label">Required field</p>}
                      <Input
                        placeholder="+44"
                        disabled={true}
                        value={gContext.goGetRegistrationData()?.phoneNumber1 || ''}
                      />
                    </Col>
                    <Col xs={12} md={4} className="text-center mb-4 mb-md-0">
                      {props.formSubmitted && !gContext.goGetRegistrationData().phoneNumber2 && gContext.goGetRegistrationData().LOAAuthorized && <p className="invalid-label">Required field</p>}
                      <Input
                        placeholder="1234"
                        value={gContext.goGetRegistrationData()?.phoneNumber2 || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePhoneTransferField(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={4} className="text-right mb-4 mb-md-0">
                      {props.formSubmitted && !gContext.goGetRegistrationData().phoneNumber3 && gContext.goGetRegistrationData().LOAAuthorized && <p className="invalid-label">Required field</p>}
                      <Input
                        value={gContext.goGetRegistrationData()?.phoneNumber3 || ''}
                        placeholder="567890"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          gContext.goSetRegistrationData({
                            ...gContext.goGetRegistrationData(),
                            phoneNumber3: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </AnimateHeight>
            </Row>
          </Col>
        </>
      }
    </Row >
  );
};

export default YouPhoneExtension;
